<template>
  <div class="renderTable">
    <el-table
      ref="table"
      :data="tableData"
      :height="height"
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column
        label="序号"
        type="index"
        width="50"
        align="center"
      ></el-table-column>
      <template>
        <el-table-column
          v-for="item in tableHeader" :key="item.id"
          :label="item.name"
          :prop="item.prop"
          :min-width="item.minWidth"
          align="center"
        >
       
        
        </el-table-column>
      </template>
      <el-table-column
        label="操作"
        align="center"
        prop="bihua"
        width="150"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="show(scope.row)">审核 </el-button>
          <!-- <el-popconfirm
            title="确定删除吗？"
            @onConfirm="handleDelte(scope.row.merchantCode)"
            @confirm="handleDelte(scope.row.merchantCode)"
          >
            <el-button slot="reference" type="text" style="color: red"
              >删除</el-button
            >
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  props: {
    tableHeader: {
      type: Array,
      default: []
    },
    tableData: {
      type: Array,
      default: []
    },
    height: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    show(row) {
      this.$emit('show', row);
    },
    async handleDelte(merchantCode) {
      const res = await this.$http.post("/merchant/deleteMerchant", {
        merchantCode
      });
      if (res.data.code === 200) {
        this.$message.success("删除成功");
        eventBus.emit("delSuccess");
      } else {
        this.$message.error(res.data.message);
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-button {
  padding: 0 8px;
}
</style>
