<template>
  <div class="box">
    <div class="search">
      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="用户ID：" prop="userName">
              <el-input v-model="queryInfo.condition.userName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="用户昵称：" prop="userNickName">
              <el-input v-model="queryInfo.condition.userNickName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="用户手机号：" prop="userPhone">
              <el-input v-model="queryInfo.condition.userPhone" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="兑换时间：" prop="creatTime">
              <el-input v-model="queryInfo.condition.creatTime" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="7" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>

        </el-row>
      </el-form>
    </div>
    <div class="table">
      <div class="add">
        <div style="display:flex;font-size: 12px;color: #409eff;">
          <div style="margin-right: 20px;">兑换金额 ()</div>
          <div>赠送金额 ()</div>
        </div>
        <el-button type="primary" icon="el-icon-circle-plus-outline">导出</el-button>
      </div>

      <table-one :tableHeader="tableHeader" :tableData="tableData" :height="height" :loading="loading" @show='sh' />
    </div>
    <div class="block">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.currPage" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <el-dialog title="店铺审核详情" :visible.sync="showDialog" width="800px" height="400px">
      <div class="dia">
        <div class="diaone">
          <div>申请人：{{ detail.creatName }}</div>
          <div>用户昵称：{{ detail.userNickName }}</div>
          <div>用户手机号：{{ detail.userPhone }}</div>
          <div>兑换金额：{{ detail.faceValue }}</div>
          <div>赠送金额：{{ detail.giveValue }}</div>
          <div>兑换类型：{{ detail.type }}</div>
          <div>储值卡ID： {{ detail.cardNo }}</div>
        </div>
        <div class="diatwo">
          <div>审核流程</div>
          <div>
            <el-steps class="teps" reverse="false" finish-status="success" align-center direction="vertical"
              :rtl="true">
              <el-step>
                <template slot="description">
                  <div class="shname">申请人</div>
                  <div class="shbox">
                    <div class="shbox-left">
                      <img src="../../assets/images/ding.png" alt="">
                      <div>{{ detail.creatName }}</div>
                    </div>
                    <div class="shbox-right">
                      <span>{{ detail.auditstatus }}</span>{{ detail.creatTime }}
                    </div>
                  </div>

                </template>
              </el-step>
              <el-step>
                <template slot="description">
                  <div class="shname" style="font-size: #000;">审核人</div>
                  <div class="shbox">
                    <div class="shbox-left">
                      <img src="../../assets/images/ding.png" alt="">
                      <div style="line-height: 30px;font-size: #000;">{{ detail.auditName }}</div>
                    </div>
                    <div class="shbox-right" style="font-size: #000;">
                      <span>{{ detail.auditstatus }}</span>{{ detail.creatTime }}
                    </div>
                  </div>

                </template>
              </el-step>
            </el-steps>
          </div>
        </div>
        <div class="diathree">
          <div style="width: 100px;">审核意见：</div>
          <el-input type="text" v-model="auditMessage"></el-input>
        </div>
        <div class="diafour">
          <el-button type="danger" @click="nook(detail.id)">驳回</el-button>
          <el-button type="primary" @click="ok(detail.id)">通过 </el-button>
        </div>


      </div>
    </el-dialog>



  </div>
</template>

<script>
import tableOne from "./tablethree/index.vue";
export default {
  components: {
    tableOne,
  },
  data() {
    return {
      auditMessage: '',//审核意见
      total: 1,
      showDialog: false,
      queryInfo: {
        condition: {
          auditStatus: 1,
          memberId: "",
          memberName: '',
          phone: '',
          creatTime: ''
        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10

      },
      tableData: [],
      tableHeader: [
        {
          name: "用户ID",
          prop: "userName",
          minWidth: "100px"
        },
        {
          name: "用户昵称",
          prop: "userNickName",
          minWidth: "100px"
        },
        {
          name: "会员等级",
          prop: "vipLevel",
          minWidth: "100px"
        },
        {
          name: "手机号",
          prop: "userPhone",
          minWidth: "100px"
        },
        {
          name: "兑换金额",
          prop: "faceValue",
          minWidth: "100px"
        },
        {
          name: "赠送金额",
          prop: "giveValue",
          minWidth: "100px"
        },
        {
          name: "账户余额",
          prop: "balance",
          minWidth: "100px"
        },
        {
          name: "兑换时间",
          prop: "creatTime",
          minWidth: "160px"
        },

        {
          name: "兑换方式",
          prop: "type",
          minWidth: "100px"
        },

      ],
      headerHeight: 0,
      height: window.innerHeight - 380, //表格高度
      loading: false,
      rightdata: [1, 2],
      detail: {}
    };
  },
  mounted() {
    this.getList();

  },
  methods: {
    added() {
      this.$router.push({ path: '/auditdetail' })
    },
    sh(row) {
      console.log(row);
      var that = this;
      that.$http.post("/audit/queryOne", {
        id: row.id
      }).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.detail = response.data.data
        }
      });
      that.showDialog = true
    },
    // 搜索
    search() {
      this.getList()
    },
    // 重置
    reset() {
      this.queryInfo = {
        condition: {
          auditStatus: 1
        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10

      }
      this.getList()
    },
    //获取数据
    getList() {
      var that = this;
      this.loading = true
      that.$http.post("/audit/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
        that.loading = false
      });
    },
    search() {
      this.queryInfo.currPage = 1;
      this.getList()
    },
    reset() {
      this.queryInfo.condition = {
        auditStatus:1
      }
      this.queryInfo.currPage = 1;
      this.getList()
    },
    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
    ok(id){
      const userInfo = JSON.parse(localStorage.getItem('userInfo')).userInfo

      var that = this;
      that.$http.post("/audit/audit", {
        id: id,
        auditMessage: that.auditMessage,
        auditstatus: 2,
        auditName: userInfo.nickName
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.auditMessage = ''
          that.showDialog = false
          that.getList()
        }
      });
    },
    nook(id) {
      const userInfo = JSON.parse(localStorage.getItem('userInfo')).userInfo

      var that = this;
      that.$http.post("/audit/update", {
        id: id,
        auditMessage: that.auditMessage,
        auditstatus: 3,
        auditName: userInfo.nickName
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.getList()
          that.showDialog = false
          that.auditMessage = ''
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.box {

  .search {
    height: 115px;
    background-color: #fff;
    box-sizing: border-box;
    padding-top: 20px;
    box-sizing: border-box;

  }

  .table {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;

    .add {
      display: flex;
      justify-content: space-between;

    }

  }

  .dia {
    box-sizing: border-box;
    padding: 0 30px;
    // height: 600px;
    overflow-y: scroll;

    .diaone {
      width: 680px;
      height: 200px;
      padding: 10px;
      border-radius: 8px;
      background-color: rgba(230, 230, 230, 0.26);
      box-sizing: border-box;

      div {
        margin-bottom: 10px;
      }
    }

    .diatwo {
      margin-top: 30px;
      width: 680px;

      padding: 10px;
      border-radius: 8px;
      background-color: rgba(230, 230, 230, 0.26);
      box-sizing: border-box;

      .shname {
        //
      }

      .shbox {
        display: flex;
        justify-content: space-between;

        .shbox-left {
          display: flex;
          background: #f3f3f3;
          width: 90px;
          height: 48px;
          box-sizing: border-box;
          padding-left: 15px;
          padding-top: 10px;

          img {
            width: 30px;
            height: 30px;
          }
        }

        .shbox-right {
          span {
            color: #409eff;
          }
        }
      }
    }

    .diathree {
      width: 680px;
      display: flex;
      border-radius: 8px;
      background-color: rgba(230, 230, 230, 0.26);
      padding: 20px;
      margin-top: 20px;
      box-sizing: border-box;

      input {
        background-color: rgba(230, 230, 230, 0.26);
        border: rgba(230, 230, 230, 0.26);
      }
    }

    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 230px;
    }
  }

  /deep/ .el-step__icon.is-text {
    background-color: #02a7f0;
    color: #fff;
    border: 2px solid #02a7f0;
  }
}

/deep/ .el-step__description {
  padding-right: 0px;

}
</style>